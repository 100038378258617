body {
  min-height: 100vh;
  overflow: auto;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
